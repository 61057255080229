<template>
  <div class="container">
    <div class="catalog" id="catalog">
      <div class="catalog-search" v-if="this.$store.getters.width >= 1200">
        <div class="position-search">
          <label class="label">Место поиска</label>
          <input type="text" placeholder="Поиск" v-model="data.place">
          <transition enter-active-class="animate__animated animate__flipInX"
                      leave-active-class="animate__animated animate__flipOutX">
            <div class="search-result" v-if="this.$store.state.searchTrigger">
              <div class="result-list">
                <template v-if="this.filters.cities.length > 0">
                  <div class="list-label">Город</div>
                  <div class="result-list-item" v-for="city in filters.cities" :key="city.id"
                       @click="selectCity(city)">
                    {{ city.name }}
                  </div>
                </template>
                <template v-if="this.filters.properties.length > 0">
                  <div class="list-label">Отели</div>
                  <div class="result-list-item" v-for="property in filters.properties" :key="property.id"
                       @click="selectProperty(property)">
                    {{ property.name }}
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
        <div class="date">
          <label class="label">Даты бронирования</label>
          <date-picker2 range v-model="data.date" placeholder="Даты бронирования" :format="'DD-MM-YYYY'"
                        :value-type="'YYYY-MM-DD'" :disabled-date="this.$core.disabledDate"></date-picker2>
        </div>
        <div class="form-group">
          <label class="label" @click="triggerPlacement">Кол-во участников</label>
          <input type="text" class="search-room" v-model="selectedRoom" :readonly="true" @click="triggerPlacement">
          <transition name="fade">
            <div class="select-placement" v-if="this.$store.state.selectPlacement">
              <div class="placement-item">
                <div class="placement-item-titles">
                  <div class="placement-item-title">Взрослые</div>
                  <div class="placement-item-sub-title">старше 17 лет</div>
                </div>
                <div class="placement-item-counter">
                  <button class="btn" @click="updateCounter('adults', 0)" :disabled="data.adults < 2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                      <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                    </svg>
                  </button>
                  <input class="counter" v-model="data.adults" :disabled="true"/>
                  <button class="btn" @click="updateCounter('adults', 1)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                      <path fill="currentColor"
                            d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="placement-item">
                <div class="placement-item-titles">
                  <div class="placement-item-title">Дети</div>
                  <div class="placement-item-sub-title">от 0 до 17 лет</div>
                </div>
                <div class="placement-item-counter">
                  <button class="btn" @click="updateCounter('children', 0)" :disabled="data.children < 1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                      <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                    </svg>
                  </button>
                  <input class="counter" v-model="data.children" :disabled="true"/>
                  <button class="btn" @click="updateCounter('children', 1)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                      <path fill="currentColor"
                            d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <button class="btn btn-primary search-hotel" @click="getServicesInfo">Поиск</button>
      </div>
      <div class="catalog-search-mobile"
           v-else-if="this.$store.getters.width < 1200 && this.$store.getters.scroll + 400 < this.catalogHeight">
        <div class="toggle-top-catalog" @click="scrollTop()">
          <div class="circle-top-catalog">
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.355112 6.00899C-0.118458 6.48255 -0.118458 7.25036 0.355112 7.72393C0.828681 8.19749 1.59649 8.19749 2.07006 7.72393L6.86671 2.92729L11.661 7.72156C12.1333 8.19387 12.899 8.19387 13.3714 7.72156C13.8437 7.24925 13.8437 6.48348 13.3714 6.01117L7.71443 0.354236C7.24212 -0.118075 6.47635 -0.118075 6.00405 0.354236C5.94458 0.413703 5.8926 0.477821 5.84811 0.54542C5.78694 0.587274 5.72876 0.635357 5.67445 0.689668L0.355112 6.00899Z"
                    fill="black"/>
            </svg>
          </div>
        </div>
        <div class="left" @click="toggleSearch">
          <div class="search-icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.55295 17.1059C10.4506 17.1055 12.2936 16.4702 13.7884 15.3012L18.4883 20.0011L20 18.4893L15.3002 13.7895C16.4698 12.2945 17.1054 10.4511 17.1059 8.55295C17.1059 3.83707 13.2688 0 8.55295 0C3.83707 0 0 3.83707 0 8.55295C0 13.2688 3.83707 17.1059 8.55295 17.1059ZM8.55295 2.13824C12.0907 2.13824 14.9677 5.01523 14.9677 8.55295C14.9677 12.0907 12.0907 14.9677 8.55295 14.9677C5.01523 14.9677 2.13824 12.0907 2.13824 8.55295C2.13824 5.01523 5.01523 2.13824 8.55295 2.13824Z"
                  fill="black"/>
            </svg>
          </div>
          <div class="filter-info">
            <span>Кисловодск</span>
            <span>3 дня - взрослых - 2, детей - 1 </span>
          </div>
        </div>
        <div class="right">
          <div class="filter-toggle" @click="toggleFilter">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M16.9998 3.55556C16.9998 4.95527 15.8475 6.11111 14.3998 6.11111C12.9521 6.11111 11.7998 4.95527 11.7998 3.55556C11.7998 2.15584 12.9521 1 14.3998 1C15.8475 1 16.9998 2.15584 16.9998 3.55556Z"
                  stroke="white" stroke-width="2"/>
              <line x1="10.7998" y1="3.66699" x2="-0.000195503" y2="3.66699" stroke="white" stroke-width="2"/>
              <path
                  d="M6.2 12.4442C6.2 13.8439 5.04769 14.9998 3.6 14.9998C2.15231 14.9998 1 13.8439 1 12.4442C1 11.0445 2.15231 9.88867 3.6 9.88867C5.04769 9.88867 6.2 11.0445 6.2 12.4442Z"
                  stroke="white" stroke-width="2"/>
              <line x1="18" y1="12.5557" x2="7.2" y2="12.5557" stroke="white" stroke-width="2"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="catalog-main">
        <div class="catalog-filter" v-if="this.$store.getters.width >= 1200">
          <div class="form-group">
            <label class="label">Найти по названию</label>
            <input type="text" placeholder="Название услуги" v-model="data.filter.title"/>
          </div>
          <div class="check-filter">
            <label class="label">Отфильтровать</label>
            <div class="checkbox-custom" @click="filterActive('vat')">
              <input type="checkbox" v-model="data.filter.onlyAdults" true-value="1"
                     false-value="0">
              <label>Только взрослые</label>
            </div>
          </div>
          <div class="price-filter">
            <label class="label">по цене (руб.)</label>
            <div class="inputs" style="display: flex; justify-content: space-between;">
              <input type="text" v-model="data.filter.priceFrom" style="height: 40px; margin-right: 3px;"
                     placeholder="Цена от">
              <input type="text" v-model="data.filter.priceTo" style="height: 40px; margin-left: 3px;"
                     placeholder="Цена до">
            </div>
          </div>
          <div class="filter-buttons">
            <button class="btn btn-canceled">Сбросить</button>
            <button class="btn btn-primary" @click="getServicesInfo">Показать услуги</button>
          </div>
        </div>
        <div class="catalog-items">
          <div class="catalog-items-sorting" v-if="this.$store.getters.width >= 1200">
            <span>Сортировать по</span>
            <div class="sorting-items">
              <div class="sorting-item" :class="[data.sorting.name.active ? 'active ' + data.sorting.name.type : '']"
                   @click="switchSorting('name')">
                названию
              </div>
              <div class="sorting-item" :class="[data.sorting.price.active ? 'active ' + data.sorting.price.type : '']"
                   @click="switchSorting('price')">
                цене
              </div>
            </div>
          </div>
          <div class="items-info">
            <p>
              {{ this.$core.declination(getServices.length, ['Найдена', 'Найдено', 'Найдено']) }}
              {{ getServices.length }}
              {{ this.$core.declination(getServices.length, ['услуга', 'услуги', 'услуг']) }}
            <p v-if="data.date.length > 0">{{ dateRange }}</p>
          </div>
          <div class="items">
            <div class="item" v-for="(service, index) in getServices" :key="index">
              <div class="left">
                <Splide :options="settings" v-if="service.images && service.images.length > 0">
                  <SplideSlide v-for="(image, index) in service.images" :key="index">
                    <img :src="'https://api.partner.alamat.ru' + image.path">
                  </SplideSlide>
                </Splide>
              </div>
              <div class="right">
                <div class="item-top">
                  <div class="item-top-left" @click="openCard(service.id)">
                    <div class="item-name">
                      <span>{{ service.title }}</span>
                      <div class="item-stars">
                        <img src="@/assets/images/catalog/star.png" v-for="index in 5"
                             :key="index">
                      </div>
                    </div>
                    <div class="item-position">
                      <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.50288 1C3.46859 1 1 3.48482 1 6.53906C1 7.57099 1.28398 8.57805 1.82143 9.45175L6.18964 16.5373C6.27332 16.6731 6.42073 16.7556 6.57928 16.7556H6.58291C6.74283 16.7543 6.89055 16.6693 6.9727 16.5312L11.2296 9.37691C11.7374 8.52168 12.0058 7.54037 12.0058 6.53906C12.0058 3.48482 9.53716 1 6.50288 1ZM10.4425 8.90295L6.57234 15.4073L2.60094 8.96536C2.15359 8.23814 1.91103 7.39916 1.91103 6.53906C1.91103 3.99386 3.9743 1.91702 6.50288 1.91702C9.03145 1.91702 11.0917 3.99386 11.0917 6.53906C11.0917 7.37365 10.8651 8.19118 10.4425 8.90295Z"
                            fill="#005BFF" stroke="#005BFF" stroke-width="0.4"/>
                        <path
                            d="M6.5029 3.76953C4.98576 3.76953 3.75146 5.01194 3.75146 6.53906C3.75146 8.05643 4.96567 9.30859 6.5029 9.30859C8.05909 9.30859 9.25434 8.03975 9.25434 6.53906C9.25434 5.01194 8.02005 3.76953 6.5029 3.76953ZM6.5029 8.39157C5.48619 8.39157 4.6625 7.5597 4.6625 6.53906C4.6625 5.52098 5.49147 4.68655 6.5029 4.68655C7.51433 4.68655 8.34025 5.52098 8.34025 6.53906C8.34025 7.5448 7.53573 8.39157 6.5029 8.39157Z"
                            fill="#005BFF" stroke="#005BFF" stroke-width="0.4"/>
                      </svg>
                      <span>{{ service.address }}</span>
                    </div>
                    <div class="item-description">
                      <p>{{ service.short_desc }}</p>
                    </div>
                  </div>
                  <div class="item-top-right">
                    <div class="rating">
                      <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H40V50L20 42L0 50V0Z" fill="#4AB502"/>
                      </svg>
                      <span>7,9</span>
                    </div>
                    <div class="item-prices">
                      <div class="item-price" @click="$router.push({path: '/services/' + service.id})">
                        <span class="price">{{ service.price }} ₽</span>
                        <span class="item-period">за {{ service.duration }}
                      {{ $core.declination(service.duration, ['час', 'часа', 'часов']) }}</span>
                      </div>
                      <button class="btn btn-primary item-show" @click="showServiceModal(service)">Посмотреть билеты
                      </button>
                    </div>
                  </div>
                </div>
                <div class="item-bottom" @click="$router.push({path: '/services/' + service.id})">
                  <div>
                    <p>Возраст от {{ service.age_limit }}+, максимальное количество человек в группе: {{
                        service.player_to
                      }}</p>
                    <p>Длительность: {{ service.duration }} ч</p>
                  </div>
                </div>
              </div>
              <div class="rating-mobile">
                <div class="rating-block">
                  <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H40V50L20 42L0 50V0Z" fill="#4AB502"/>
                  </svg>
                  <span>7,9</span>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-white" v-waypoint="{ active: true, callback: onWaypoint }">Показать ещё</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import {mapGetters} from "vuex";

export default {
  name: 'catalog',
  components: {DatePicker2},
  data: function () {
    return {
      load: true,
      data: {
        place: null,
        date: [],
        adults: 1,
        children: 0,
        limit: 12,
        sorting: {
          name: {
            active: false,
            type: 'desc'
          },
          review: {
            active: false,
            type: 'desc'
          },
          popular: {
            active: false,
            type: 'desc'
          },
          price: {
            active: false,
            type: 'desc'
          },
        },
        filter: {
          title: '',
          onlyAdults: 0,
          priceFrom: '',
          priceTo: ''
        },
      },
      settings: {
        rewind: true,
        lazyLoad: 'nearby',
        pagination: false,
        breakpoints: {
          992: {
            perPage: 2,
          },
          600: {
            perPage: 1
          }
        }
      },
    }
  },
  async mounted() {
    await this.getServicesInfo();
  },
  computed: {
    ...mapGetters(['getServices']),
    selectedRoom: function () {
      let totalGuests = parseInt(this.data.adults) + parseInt(this.data.children);
      return totalGuests + ' ' + this.$core.declination(totalGuests, ['участник', 'участника', 'участников']);
    },
    dateRange: function () {
      let countClient = this.data.adults + this.data.children;
      return 'c ' + this.$moment(this.data.date[0]).format('DD.MM') + ' по '
          + this.$moment(this.data.date[1]).format('DD.MM') + ', '
          + ' для ' + countClient + ' '
          + this.$core.declination(countClient, ['участника', 'участников', 'участников']);
    },
  },
  methods: {
    showServiceModal: function (item) {
      this.$store.dispatch('setAboutService', item);
      this.$root.$emit('openModal', 'serviceBooking')
    },
    updateCounter: function (variable, type) {
      if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
        this.data[variable]--;
      } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
        this.data[variable]--;
      } else {
        this.data[variable]++;
      }
    },
    filterActive: function (val) {
      this.data.filter[val] = !this.data.filter[val];
    },
    onWaypoint({going}) {
      if (going === this.$waypointMap.GOING_IN) {
        // this.data.limit += 12;
        // this.getSearch();
      }
    },
    triggerPlacement: function () {
      this.$store.state.selectPlacement = !this.$store.state.selectPlacement;
    },
    switchSorting: function (val) {
      let $this = this;

      if (this.data.sorting[val].type === 'desc' && this.data.sorting[val].active !== true) {
        this.data.sorting[val].active = true;
      } else if (this.data.sorting[val].active === true && this.data.sorting[val].type === 'desc') {
        this.data.sorting[val].type = 'asc';
        this.data.sorting[val].active = true;
      } else {
        this.data.sorting[val].active = false;
      }

      Object.keys(this.data.sorting).forEach(function (key) {
        if (key !== val) {
          $this.data.sorting[key].active = false;
        }
      });
    },

    openCard: function (id) {
      this.$router.push({
        path: '/services/' + id,
        query: {
          dateFrom: this.data.date.length > 0 ? this.data.date[0] : '',
          dateTo: this.data.date.length > 0 ? this.data.date[1] : '',
          adults: this.data.adults,
          children: this.data.children
        },
      });
    },

    // запросы на сервер

    getServicesInfo: async function () {
      let payload = {
        limit: this.data.limit,
        place: this.data.place,
        adults: this.data.adults,
        children: this.data.children,
        title: this.data.filter.title,
        onlyAdults: this.data.filter.onlyAdults,
        priceFrom: this.data.filter.priceFrom,
        priceTo: this.data.filter.priceTo,
        dateFrom: this.data.date.length > 0 ? this.data.date[0] : '',
        dateTo: this.data.date.length > 0 ? this.data.date[1] : '',
      }
      await this.$store.dispatch('getServices', payload).then(() => {
        this.$router.replace({name: 'services-catalog', query: payload});
      })
    },
  }
}
</script>

<style scoped>
.item-bottom {
  display: flex;
  flex-direction: column;
}

.item-bottom a.detailed {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #004ED8;
  margin-top: 10px;
}

.item-description {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-top: 10px;
}
</style>
